import { Action, ActionType } from './reducers'

import { DataSubjectPreferences } from '../shared/types'
import React from 'react'
import apiClient from '../lib/apiClient'
import { isEmpty } from 'ramda'

interface AxiosResponse {
  success: boolean
  message?: string
  payload?: DataSubjectPreferences
  sessionId?: string
  error?: any
}

interface ErrorMapping {
  [key: string]: string
}

export const signInSession = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  try {
    const response = await apiClient.get<AxiosResponse>('/get_user')
    if (!response.data.success && response.data.message) {
      throw new Error(response.data.message)
    }
    dispatch({ type: ActionType.SignInSession })
    const { payload: userPreferences } = response.data || {}
    if (
      userPreferences &&
      userPreferences.consent.length &&
      !isEmpty(userPreferences.dataElements)
    ) {
      dispatch({ type: ActionType.SetUserPreferences, payload: { userPreferences } })
    } else {
      throw new Error('User does not contain any data.')
    }
  } catch (err) {
    dispatch({ type: ActionType.SignOut })
    throw err
    // TODO: set error notification here.
  }
}

const getError = (code: string, message: string): string => {
  const errorMapping: ErrorMapping = {
    UserNotFoundException:
      "User with the given details does not exist. <a class='MuiTypography-colorPrimary' href='/join/'>Join us!</a>",
    UsernameExistsException:
      "This account is already registered, please <a class='MuiTypography-colorPrimary' href='/login/'>click here to login</a>.",
    UserLambdaValidationException:
      "This account is already registered, please <a class='MuiTypography-colorPrimary' href='/login/'>click here to login</a>.",
  }

  return errorMapping[code] || message
}

export const signIn = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  password: string,
  rememberMe: boolean,
  captchaResponse: string | null,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/sign_in', {
      email,
      password,
      rememberMe,
      captchaResponse,
    })
    if (!response.data.success) {
      const errorMessage =
        getError(response.data.error.code, response.data.error.message) || response.data.message
      throw new Error(errorMessage || 'User could not be authenticated.')
    }
    dispatch({ type: ActionType.SignIn })
    const { payload: userPreferences } = response.data || {}
    if (
      userPreferences &&
      userPreferences.consent.length &&
      !isEmpty(userPreferences.dataElements)
    ) {
      dispatch({ type: ActionType.SetUserPreferences, payload: { userPreferences } })
    } else {
      throw new Error('User does not contain any data.')
    }
  } catch (err) {
    dispatch({ type: ActionType.SignOut })
    throw err
  }
}

/* ===== Registration ===== */

export const signUp = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  password: string,
  userPreferences: DataSubjectPreferences,
  captchaResponse: string | null,
): Promise<void> => {
  
  try {
    const response = await apiClient.post<AxiosResponse>('/register', {
      email,
      password,
      userPreferences,
      captchaResponse,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.SignUp, payload: { email } })
      // Log/Notify.
    } else {
      const errorMessage =
        getError(response.data.error.code, response.data.error.message) || response.data.message
      throw new Error(errorMessage || 'User could not be registered')
    }
  } catch (err) {
    console.error(err)
    const message = err?.message ?? 'Failed to register user'
    throw new Error(message)
  }
}

/* ===== Password reset ===== */

export const sendForgotPasswordCode = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  captchaResponse: string | null,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/forgot', {
      email,
      captchaResponse,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.ForgotPasswordSendCode, payload: { email } })
    } else {
      const errorMessage = response.data?.error?.message || response.data?.message
      throw new Error(errorMessage)
    }
  } catch (err) {
    throw new Error(`Failed to send email, please try again later: ${err?.message}`)
  }
}

export const confirmForgotNewPassword = async (
  dispatch: React.Dispatch<Action>,
  email: string,
  code: string,
  password: string,
  captchaResponse: string | null,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/new_password', {
      email,
      code,
      password,
      captchaResponse,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.ForgotPasswordSetNew, payload: { email } })
      // Log/Notify.
    } else {
      const errorMessage = response.data?.error?.message || response.data?.message
      throw new Error(errorMessage || 'Could not reset your password, please try again later.')
    }
  } catch (err) {
    throw new Error(`Failed to reset password: ${err?.message}`)
  }
}

/* ===== Edit profile ===== */

export const setUserPreferences = async (
  dispatch: React.Dispatch<Action>,
  userPreferences: DataSubjectPreferences,
  captchaResponse: string | null,
): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/edit_user', {
      userPreferences,
      captchaResponse,
    })
    if (response.data.success) {
      dispatch({ type: ActionType.SetUserPreferences, payload: { userPreferences } })
    } else {
      throw new Error(response.data.message)
    }
  } catch (err) {
    throw new Error(err.message ?? 'Updating profile information failed.')
  }
}

export const changePassword = async (
  dispatch: React.Dispatch<Action>,
  oldPassword: string,
  newPassword: string,
  captchaResponse: string | null,
): Promise<void> => {
  const response = await apiClient.post<AxiosResponse>('/change_password', {
    oldPassword,
    newPassword,
    captchaResponse,
  })
  if (response.data.success) {
    dispatch({ type: ActionType.ChangePassword })
    // Log/Notify.
  } else {
    console.error(response.data.error)
    throw new Error('Failed to change password')
  }
}

export const signOut = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/sign_out')
    if (response.data.success) {
      dispatch({ type: ActionType.SignOut })
      // Log/Notify.
    }
  } catch (err) {
    // Log/Notify.
  }
}

/* ===== Check NickName Exists ===== */

export const checkIfNickNameExists = async (nickname?: string): Promise<AxiosResponse> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/nickname/check', {
      nickname,
    })
    return response.data
  } catch (err) {
    console.error(err)
    const message = err?.message ?? 'Error with nickname'
    throw new Error(message)
  }
}

/* ===== Save Nickname To DynamoDB ===== */

export const saveNickNameToForumDynamoDB = async ({
  nickname,
  sessionId,
}: {
  nickname: string
  sessionId: string
}): Promise<AxiosResponse> => {
  try {
    const response = await apiClient.post<AxiosResponse>('/nickname/save', {
      nickname,
      sessionId,
    })
    return response.data
  } catch (err) {
    console.error(err)
    const message = err?.message ?? 'Error with nickname'
    throw new Error(message)
  }
}
